import styled from "styled-components";

import { Snow } from "./styles";

const Background = styled.div`
  opacity: 0.5;
  filter: drop-shadow(0 0 10px white);
  width: 100vw;
  height: 100vh;
  position: absolute;
  inset: 0;
  overflow: hidden;

  ${Snow}
`

const SnowFlakes = () => {

  return (
    <Background>
      { [... new Array(200)].map((item, index) => (
        <div key={index} className="snow"></div>
      ))}
    </Background>
  )
}

export default SnowFlakes;