import type { NextPage } from 'next';
import Head from 'next/head';
import styled from 'styled-components';
import Button from '../components/Button';
import { motion } from "framer-motion";
import SnowFlakes from '../components/SnowFlakes';
import Script from 'next/script';

const Main = styled.section`
  background: black;
  min-height: 100vh;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  
  .content {
    text-align: center;
    z-index: 10;
    position: relative;

    h1 {
      color: white;

      span {
        display: inline-block;
      }

      .em {
        @supports (-webkit-text-stroke: 1px black) {
          -webkit-text-fill-color: rgba(0, 0, 0, 0); /* Will override color (regardless of order) */
          -webkit-text-stroke-width: 0.05rem;
          -webkit-text-stroke-color: white; 

          @media ${({ theme }) => theme.mediaQuery.smallPhone} {
            -webkit-text-stroke-width: 0.075rem;
          }

          @media ${({ theme }) => theme.mediaQuery.smallTablet} {
            -webkit-text-stroke-width: 0.1rem;
          }
        }
      }
    }

    .actions {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-wrap: wrap;
      padding: 1rem 1rem;

      @media ${({ theme }) => theme.mediaQuery.phone} {
        flex-wrap: nowrap;
      }


      > * {
        display: block;
        width: 100%;
        margin-bottom: 0.5rem;
        max-width: 20rem;

        @media ${({ theme }) => theme.mediaQuery.phone} {
          display: inline-block;
          max-width: none;
          width: auto;
        }
      }

      .icon-link {
        order: 2;

        @media ${({ theme }) => theme.mediaQuery.phone} {
          order: 1;
        }

        > * {
          display: inline-block;
          vertical-align: middle;
        }

        p {
          margin-left: 0.5rem;

          @media ${({ theme }) => theme.mediaQuery.phone} {
            display: none;
          }
        }
      }

      .go {
        margin-top: 0;
        margin-left: 0.5rem;
        order: 1;



        .fa-arrow-right {
          margin-left: 0.5rem;
        }
      }
    }
  }
`

const contentContainer = {
  visible: {
    transition: {
      staggerChildren: 0.2,
    }
  }
}

const zoneItem = {
  hidden: {
    opacity: 0,
  },
  visible: {
    opacity: 1,
    transition: { 
      duration: 0.75, 
      ease: [0.77, 0, 0.175, 1],
    }
  }
}

const contentItem = {
  ...zoneItem,
  hidden: {
    ...zoneItem.hidden,
    translateY: 50,
  },
  visible: {
    ...zoneItem.visible,
    translateY: 0,
  }
}

const Home: NextPage = () => {

  return (
    <>
      <Head>
        <title>WinterFest</title>
        <meta name="description" content="Shed the jacket and the cold! Join us for unforgettable sonic experiences!" />
        <meta name="keywords" content="winterfest, music, shenseea, skillibeng, cayman islands, toronto, mary j blige, keisha cole" />
        <meta name="robots" content="index, follow" />
        <meta http-equiv="Content-Type" content="text/html; charset=utf-8" />
        <meta name="language" content="English" />

        <meta property="og:type" content="website" />
        <meta property="og:url" content="https://winterfestlive.ca/cayman-islands" />
        <meta property="og:title" content="WinterFest - Cayman Islands" />
        <meta property="og:description" content="Shed the jacket and the cold! Join us for unforgettable sonic experiences!" />
        <meta property="og:image" content="https://winterfestlive.ca/winterfest-social.png" />

        <meta property="twitter:card" content="summary_large_image" />
        <meta property="twitter:url" content="https://winterfestlive.ca/cayman-islands" />
        <meta property="twitter:title" content="WinterFest - Cayman Islands" />
        <meta property="twitter:description" content="Shed the jacket and the cold! Join us for unforgettable sonic experiences!" />
        <meta property="twitter:image" content="https://winterfestlive.ca/winterfest-social.png" />
      </Head>
      <Script async type="text/javascript" src="https://static.klaviyo.com/onsite/js/klaviyo.js?company_id=Xf4Ssr" />
      <Main>
        <motion.div
          initial="hidden"
          whileInView="visible"
          viewport={{ once: true, amount: 0.5 }}
          variants={contentContainer}
          className="content"
        >
          <h1 className="extra">
            <motion.span variants={contentItem}>Winter</motion.span>
            <motion.span variants={contentItem} className="em">Fest</motion.span>
          </h1>
          <motion.div variants={contentItem} className="actions">
            <a className="icon-link" href="https://instagram.com/eventswithskip?igshid=YmMyMTA2M2Y="><i className="fa-brands fa-instagram"></i><p>Instagram</p></a>
            <Button className="go outline-invert" href="/cayman-islands" label={<>Cayman Island Tickets <i className="fa-sharp fa-solid fa-arrow-right" /></>} />
            <Button className="go outline-invert" href="/toronto" label={<>Toronto Tickets <i className="fa-sharp fa-solid fa-arrow-right" /></>} />
            {/* <Button className="disabled go outline-invert" href="/cayman-islands" label={<>Coming soon</>} /> */}
          </motion.div>
        </motion.div>
      </Main>
      <SnowFlakes/>
    </>
  )
}

export default Home
