import { css } from "styled-components";

export const Snow = css`
  .snow {
    position: absolute;
    width: 10px;
    height: 10px;
    background: white;
    border-radius: 50%;
  }
  .snow:nth-child(1) {
    opacity: 0.443;
    transform: translate(86.0649vw, -10px) scale(0.3258);
    animation: fall-1 20s -15s linear infinite;
  }
  @keyframes fall-1 {
    47.658% {
      transform: translate(88.6861vw, 47.658vh) scale(0.3258);
    }
    to {
      transform: translate(87.3755vw, 100vh) scale(0.3258);
    }
  }
  .snow:nth-child(2) {
    opacity: 0.7116;
    transform: translate(41.08vw, -10px) scale(0.5633);
    animation: fall-2 22s -18s linear infinite;
  }
  @keyframes fall-2 {
    68.579% {
      transform: translate(45.8985vw, 68.579vh) scale(0.5633);
    }
    to {
      transform: translate(43.48925vw, 100vh) scale(0.5633);
    }
  }
  .snow:nth-child(3) {
    opacity: 0.495;
    transform: translate(55.9428vw, -10px) scale(0.5266);
    animation: fall-3 14s -21s linear infinite;
  }
  @keyframes fall-3 {
    63.86% {
      transform: translate(58.3105vw, 63.86vh) scale(0.5266);
    }
    to {
      transform: translate(57.12665vw, 100vh) scale(0.5266);
    }
  }
  .snow:nth-child(4) {
    opacity: 0.8991;
    transform: translate(36.0697vw, -10px) scale(0.8057);
    animation: fall-4 25s -4s linear infinite;
  }
  @keyframes fall-4 {
    40.169% {
      transform: translate(40.0431vw, 40.169vh) scale(0.8057);
    }
    to {
      transform: translate(38.0564vw, 100vh) scale(0.8057);
    }
  }
  .snow:nth-child(5) {
    opacity: 0.7501;
    transform: translate(93.1116vw, -10px) scale(0.9428);
    animation: fall-5 30s -22s linear infinite;
  }
  @keyframes fall-5 {
    47.238% {
      transform: translate(85.2478vw, 47.238vh) scale(0.9428);
    }
    to {
      transform: translate(89.1797vw, 100vh) scale(0.9428);
    }
  }
  .snow:nth-child(6) {
    opacity: 0.9199;
    transform: translate(18.2789vw, -10px) scale(0.9686);
    animation: fall-6 19s -17s linear infinite;
  }
  @keyframes fall-6 {
    51.004% {
      transform: translate(14.4088vw, 51.004vh) scale(0.9686);
    }
    to {
      transform: translate(16.34385vw, 100vh) scale(0.9686);
    }
  }
  .snow:nth-child(7) {
    opacity: 0.4805;
    transform: translate(61.0274vw, -10px) scale(0.7111);
    animation: fall-7 13s -13s linear infinite;
  }
  @keyframes fall-7 {
    47.917% {
      transform: translate(61.6126vw, 47.917vh) scale(0.7111);
    }
    to {
      transform: translate(61.32vw, 100vh) scale(0.7111);
    }
  }
  .snow:nth-child(8) {
    opacity: 0.356;
    transform: translate(60.296vw, -10px) scale(0.3013);
    animation: fall-8 21s -20s linear infinite;
  }
  @keyframes fall-8 {
    46.769% {
      transform: translate(56.5414vw, 46.769vh) scale(0.3013);
    }
    to {
      transform: translate(58.4187vw, 100vh) scale(0.3013);
    }
  }
  .snow:nth-child(9) {
    opacity: 0.7843;
    transform: translate(63.4887vw, -10px) scale(0.8739);
    animation: fall-9 11s -7s linear infinite;
  }
  @keyframes fall-9 {
    55.364% {
      transform: translate(67.6891vw, 55.364vh) scale(0.8739);
    }
    to {
      transform: translate(65.5889vw, 100vh) scale(0.8739);
    }
  }
  .snow:nth-child(10) {
    opacity: 0.6131;
    transform: translate(19.637vw, -10px) scale(0.5548);
    animation: fall-10 29s -25s linear infinite;
  }
  @keyframes fall-10 {
    61.864% {
      transform: translate(16.9906vw, 61.864vh) scale(0.5548);
    }
    to {
      transform: translate(18.3138vw, 100vh) scale(0.5548);
    }
  }
  .snow:nth-child(11) {
    opacity: 0.3163;
    transform: translate(38.857vw, -10px) scale(0.6062);
    animation: fall-11 15s -26s linear infinite;
  }
  @keyframes fall-11 {
    48.155% {
      transform: translate(40.0685vw, 48.155vh) scale(0.6062);
    }
    to {
      transform: translate(39.46275vw, 100vh) scale(0.6062);
    }
  }
  .snow:nth-child(12) {
    opacity: 0.8785;
    transform: translate(59.5051vw, -10px) scale(0.6359);
    animation: fall-12 28s -10s linear infinite;
  }
  @keyframes fall-12 {
    68.175% {
      transform: translate(68.0203vw, 68.175vh) scale(0.6359);
    }
    to {
      transform: translate(63.7627vw, 100vh) scale(0.6359);
    }
  }
  .snow:nth-child(13) {
    opacity: 0.4026;
    transform: translate(88.5487vw, -10px) scale(0.6063);
    animation: fall-13 15s -23s linear infinite;
  }
  @keyframes fall-13 {
    50.307% {
      transform: translate(97.0746vw, 50.307vh) scale(0.6063);
    }
    to {
      transform: translate(92.81165vw, 100vh) scale(0.6063);
    }
  }
  .snow:nth-child(14) {
    opacity: 0.3284;
    transform: translate(31.2854vw, -10px) scale(0.3893);
    animation: fall-14 14s -22s linear infinite;
  }
  @keyframes fall-14 {
    38.334% {
      transform: translate(29.2404vw, 38.334vh) scale(0.3893);
    }
    to {
      transform: translate(30.2629vw, 100vh) scale(0.3893);
    }
  }
  .snow:nth-child(15) {
    opacity: 0.3838;
    transform: translate(84.8983vw, -10px) scale(0.6373);
    animation: fall-15 20s -25s linear infinite;
  }
  @keyframes fall-15 {
    66.157% {
      transform: translate(91.8404vw, 66.157vh) scale(0.6373);
    }
    to {
      transform: translate(88.36935vw, 100vh) scale(0.6373);
    }
  }
  .snow:nth-child(16) {
    opacity: 0.9499;
    transform: translate(9.8037vw, -10px) scale(0.3582);
    animation: fall-16 14s -28s linear infinite;
  }
  @keyframes fall-16 {
    37.58% {
      transform: translate(8.2214vw, 37.58vh) scale(0.3582);
    }
    to {
      transform: translate(9.01255vw, 100vh) scale(0.3582);
    }
  }
  .snow:nth-child(17) {
    opacity: 0.8409;
    transform: translate(3.3618vw, -10px) scale(0.6371);
    animation: fall-17 29s -4s linear infinite;
  }
  @keyframes fall-17 {
    52.53% {
      transform: translate(9.9651vw, 52.53vh) scale(0.6371);
    }
    to {
      transform: translate(6.66345vw, 100vh) scale(0.6371);
    }
  }
  .snow:nth-child(18) {
    opacity: 0.6495;
    transform: translate(42.8635vw, -10px) scale(0.4605);
    animation: fall-18 30s -26s linear infinite;
  }
  @keyframes fall-18 {
    50.6% {
      transform: translate(48.1195vw, 50.6vh) scale(0.4605);
    }
    to {
      transform: translate(45.4915vw, 100vh) scale(0.4605);
    }
  }
  .snow:nth-child(19) {
    opacity: 0.7688;
    transform: translate(33.5227vw, -10px) scale(0.4686);
    animation: fall-19 23s -23s linear infinite;
  }
  @keyframes fall-19 {
    72.882% {
      transform: translate(24.8378vw, 72.882vh) scale(0.4686);
    }
    to {
      transform: translate(29.18025vw, 100vh) scale(0.4686);
    }
  }
  .snow:nth-child(20) {
    opacity: 0.1798;
    transform: translate(54.263vw, -10px) scale(0.2534);
    animation: fall-20 30s -17s linear infinite;
  }
  @keyframes fall-20 {
    69.795% {
      transform: translate(47.0713vw, 69.795vh) scale(0.2534);
    }
    to {
      transform: translate(50.66715vw, 100vh) scale(0.2534);
    }
  }
  .snow:nth-child(21) {
    opacity: 0.8088;
    transform: translate(83.7197vw, -10px) scale(0.0944);
    animation: fall-21 14s -4s linear infinite;
  }
  @keyframes fall-21 {
    56.795% {
      transform: translate(75.9071vw, 56.795vh) scale(0.0944);
    }
    to {
      transform: translate(79.8134vw, 100vh) scale(0.0944);
    }
  }
  .snow:nth-child(22) {
    opacity: 0.3203;
    transform: translate(13.5736vw, -10px) scale(0.8878);
    animation: fall-22 19s -4s linear infinite;
  }
  @keyframes fall-22 {
    64.079% {
      transform: translate(8.0861vw, 64.079vh) scale(0.8878);
    }
    to {
      transform: translate(10.82985vw, 100vh) scale(0.8878);
    }
  }
  .snow:nth-child(23) {
    opacity: 0.2267;
    transform: translate(28.5355vw, -10px) scale(0.2469);
    animation: fall-23 17s -17s linear infinite;
  }
  @keyframes fall-23 {
    77.688% {
      transform: translate(27.5491vw, 77.688vh) scale(0.2469);
    }
    to {
      transform: translate(28.0423vw, 100vh) scale(0.2469);
    }
  }
  .snow:nth-child(24) {
    opacity: 0.5841;
    transform: translate(92.5283vw, -10px) scale(0.7282);
    animation: fall-24 10s -21s linear infinite;
  }
  @keyframes fall-24 {
    68.953% {
      transform: translate(85.0153vw, 68.953vh) scale(0.7282);
    }
    to {
      transform: translate(88.7718vw, 100vh) scale(0.7282);
    }
  }
  .snow:nth-child(25) {
    opacity: 0.2959;
    transform: translate(6.1093vw, -10px) scale(0.9395);
    animation: fall-25 20s -8s linear infinite;
  }
  @keyframes fall-25 {
    42.88% {
      transform: translate(-1.4326vw, 42.88vh) scale(0.9395);
    }
    to {
      transform: translate(2.33835vw, 100vh) scale(0.9395);
    }
  }
  .snow:nth-child(26) {
    opacity: 0.9062;
    transform: translate(42.8409vw, -10px) scale(0.14);
    animation: fall-26 30s -21s linear infinite;
  }
  @keyframes fall-26 {
    30.766% {
      transform: translate(48.4832vw, 30.766vh) scale(0.14);
    }
    to {
      transform: translate(45.66205vw, 100vh) scale(0.14);
    }
  }
  .snow:nth-child(27) {
    opacity: 0.5052;
    transform: translate(27.2279vw, -10px) scale(0.4545);
    animation: fall-27 28s -1s linear infinite;
  }
  @keyframes fall-27 {
    59.234% {
      transform: translate(17.3474vw, 59.234vh) scale(0.4545);
    }
    to {
      transform: translate(22.28765vw, 100vh) scale(0.4545);
    }
  }
  .snow:nth-child(28) {
    opacity: 0.5193;
    transform: translate(64.3887vw, -10px) scale(0.1906);
    animation: fall-28 22s -12s linear infinite;
  }
  @keyframes fall-28 {
    65.388% {
      transform: translate(55.9217vw, 65.388vh) scale(0.1906);
    }
    to {
      transform: translate(60.1552vw, 100vh) scale(0.1906);
    }
  }
  .snow:nth-child(29) {
    opacity: 0.4165;
    transform: translate(25.3678vw, -10px) scale(0.8417);
    animation: fall-29 30s -17s linear infinite;
  }
  @keyframes fall-29 {
    76.792% {
      transform: translate(25.3486vw, 76.792vh) scale(0.8417);
    }
    to {
      transform: translate(25.3582vw, 100vh) scale(0.8417);
    }
  }
  .snow:nth-child(30) {
    opacity: 0.4235;
    transform: translate(16.8523vw, -10px) scale(0.1275);
    animation: fall-30 21s -30s linear infinite;
  }
  @keyframes fall-30 {
    64.002% {
      transform: translate(22.8779vw, 64.002vh) scale(0.1275);
    }
    to {
      transform: translate(19.8651vw, 100vh) scale(0.1275);
    }
  }
  .snow:nth-child(31) {
    opacity: 0.6978;
    transform: translate(21.0318vw, -10px) scale(0.7816);
    animation: fall-31 22s -24s linear infinite;
  }
  @keyframes fall-31 {
    52.531% {
      transform: translate(13.0728vw, 52.531vh) scale(0.7816);
    }
    to {
      transform: translate(17.0523vw, 100vh) scale(0.7816);
    }
  }
  .snow:nth-child(32) {
    opacity: 0.0899;
    transform: translate(62.4242vw, -10px) scale(0.0947);
    animation: fall-32 13s -2s linear infinite;
  }
  @keyframes fall-32 {
    49.867% {
      transform: translate(70.683vw, 49.867vh) scale(0.0947);
    }
    to {
      transform: translate(66.5536vw, 100vh) scale(0.0947);
    }
  }
  .snow:nth-child(33) {
    opacity: 0.9409;
    transform: translate(68.7375vw, -10px) scale(0.9966);
    animation: fall-33 24s -30s linear infinite;
  }
  @keyframes fall-33 {
    61.474% {
      transform: translate(72.4052vw, 61.474vh) scale(0.9966);
    }
    to {
      transform: translate(70.57135vw, 100vh) scale(0.9966);
    }
  }
  .snow:nth-child(34) {
    opacity: 0.5503;
    transform: translate(48.4914vw, -10px) scale(0.6292);
    animation: fall-34 10s -29s linear infinite;
  }
  @keyframes fall-34 {
    35.14% {
      transform: translate(54.0884vw, 35.14vh) scale(0.6292);
    }
    to {
      transform: translate(51.2899vw, 100vh) scale(0.6292);
    }
  }
  .snow:nth-child(35) {
    opacity: 0.0064;
    transform: translate(56.1353vw, -10px) scale(0.5293);
    animation: fall-35 22s -27s linear infinite;
  }
  @keyframes fall-35 {
    69.305% {
      transform: translate(53.3986vw, 69.305vh) scale(0.5293);
    }
    to {
      transform: translate(54.76695vw, 100vh) scale(0.5293);
    }
  }
  .snow:nth-child(36) {
    opacity: 0.9798;
    transform: translate(41.4623vw, -10px) scale(0.5508);
    animation: fall-36 20s -16s linear infinite;
  }
  @keyframes fall-36 {
    76.789% {
      transform: translate(42.6047vw, 76.789vh) scale(0.5508);
    }
    to {
      transform: translate(42.0335vw, 100vh) scale(0.5508);
    }
  }
  .snow:nth-child(37) {
    opacity: 0.5549;
    transform: translate(10.1875vw, -10px) scale(0.254);
    animation: fall-37 28s -23s linear infinite;
  }
  @keyframes fall-37 {
    33.329% {
      transform: translate(0.6958vw, 33.329vh) scale(0.254);
    }
    to {
      transform: translate(5.44165vw, 100vh) scale(0.254);
    }
  }
  .snow:nth-child(38) {
    opacity: 0.9436;
    transform: translate(35.9753vw, -10px) scale(0.5047);
    animation: fall-38 17s -8s linear infinite;
  }
  @keyframes fall-38 {
    52.896% {
      transform: translate(37.1283vw, 52.896vh) scale(0.5047);
    }
    to {
      transform: translate(36.5518vw, 100vh) scale(0.5047);
    }
  }
  .snow:nth-child(39) {
    opacity: 0.3414;
    transform: translate(21.0393vw, -10px) scale(0.8317);
    animation: fall-39 25s -9s linear infinite;
  }
  @keyframes fall-39 {
    67.823% {
      transform: translate(20.2425vw, 67.823vh) scale(0.8317);
    }
    to {
      transform: translate(20.6409vw, 100vh) scale(0.8317);
    }
  }
  .snow:nth-child(40) {
    opacity: 0.0779;
    transform: translate(70.9116vw, -10px) scale(0.8971);
    animation: fall-40 17s -10s linear infinite;
  }
  @keyframes fall-40 {
    44.542% {
      transform: translate(80.2276vw, 44.542vh) scale(0.8971);
    }
    to {
      transform: translate(75.5696vw, 100vh) scale(0.8971);
    }
  }
  .snow:nth-child(41) {
    opacity: 0.4322;
    transform: translate(57.9742vw, -10px) scale(0.5757);
    animation: fall-41 16s -14s linear infinite;
  }
  @keyframes fall-41 {
    40.408% {
      transform: translate(60.5496vw, 40.408vh) scale(0.5757);
    }
    to {
      transform: translate(59.2619vw, 100vh) scale(0.5757);
    }
  }
  .snow:nth-child(42) {
    opacity: 0.6089;
    transform: translate(61.2775vw, -10px) scale(0.9449);
    animation: fall-42 17s -3s linear infinite;
  }
  @keyframes fall-42 {
    42.116% {
      transform: translate(59.2223vw, 42.116vh) scale(0.9449);
    }
    to {
      transform: translate(60.2499vw, 100vh) scale(0.9449);
    }
  }
  .snow:nth-child(43) {
    opacity: 0.6279;
    transform: translate(85.2256vw, -10px) scale(0.3795);
    animation: fall-43 28s -6s linear infinite;
  }
  @keyframes fall-43 {
    31.65% {
      transform: translate(78.4307vw, 31.65vh) scale(0.3795);
    }
    to {
      transform: translate(81.82815vw, 100vh) scale(0.3795);
    }
  }
  .snow:nth-child(44) {
    opacity: 0.0842;
    transform: translate(73.4968vw, -10px) scale(0.0938);
    animation: fall-44 29s -2s linear infinite;
  }
  @keyframes fall-44 {
    36.848% {
      transform: translate(83.3049vw, 36.848vh) scale(0.0938);
    }
    to {
      transform: translate(78.40085vw, 100vh) scale(0.0938);
    }
  }
  .snow:nth-child(45) {
    opacity: 0.8419;
    transform: translate(87.4519vw, -10px) scale(0.1532);
    animation: fall-45 28s -12s linear infinite;
  }
  @keyframes fall-45 {
    46.668% {
      transform: translate(95.9738vw, 46.668vh) scale(0.1532);
    }
    to {
      transform: translate(91.71285vw, 100vh) scale(0.1532);
    }
  }
  .snow:nth-child(46) {
    opacity: 0.478;
    transform: translate(44.804vw, -10px) scale(0.2312);
    animation: fall-46 17s -7s linear infinite;
  }
  @keyframes fall-46 {
    76.567% {
      transform: translate(43.824vw, 76.567vh) scale(0.2312);
    }
    to {
      transform: translate(44.314vw, 100vh) scale(0.2312);
    }
  }
  .snow:nth-child(47) {
    opacity: 0.0195;
    transform: translate(69.9266vw, -10px) scale(0.2411);
    animation: fall-47 14s -29s linear infinite;
  }
  @keyframes fall-47 {
    63.855% {
      transform: translate(65.7521vw, 63.855vh) scale(0.2411);
    }
    to {
      transform: translate(67.83935vw, 100vh) scale(0.2411);
    }
  }
  .snow:nth-child(48) {
    opacity: 0.2551;
    transform: translate(9.4744vw, -10px) scale(0.9395);
    animation: fall-48 12s -4s linear infinite;
  }
  @keyframes fall-48 {
    53.751% {
      transform: translate(9.4085vw, 53.751vh) scale(0.9395);
    }
    to {
      transform: translate(9.44145vw, 100vh) scale(0.9395);
    }
  }
  .snow:nth-child(49) {
    opacity: 0.085;
    transform: translate(63.7181vw, -10px) scale(0.3577);
    animation: fall-49 26s -17s linear infinite;
  }
  @keyframes fall-49 {
    46.98% {
      transform: translate(70.3872vw, 46.98vh) scale(0.3577);
    }
    to {
      transform: translate(67.05265vw, 100vh) scale(0.3577);
    }
  }
  .snow:nth-child(50) {
    opacity: 0.109;
    transform: translate(29.3532vw, -10px) scale(0.8876);
    animation: fall-50 30s -17s linear infinite;
  }
  @keyframes fall-50 {
    50.437% {
      transform: translate(28.5248vw, 50.437vh) scale(0.8876);
    }
    to {
      transform: translate(28.939vw, 100vh) scale(0.8876);
    }
  }
  .snow:nth-child(51) {
    opacity: 0.1321;
    transform: translate(54.3381vw, -10px) scale(0.0145);
    animation: fall-51 10s -10s linear infinite;
  }
  @keyframes fall-51 {
    72.818% {
      transform: translate(63.8392vw, 72.818vh) scale(0.0145);
    }
    to {
      transform: translate(59.08865vw, 100vh) scale(0.0145);
    }
  }
  .snow:nth-child(52) {
    opacity: 0.1923;
    transform: translate(85.0751vw, -10px) scale(0.6668);
    animation: fall-52 13s -2s linear infinite;
  }
  @keyframes fall-52 {
    45.073% {
      transform: translate(83.1059vw, 45.073vh) scale(0.6668);
    }
    to {
      transform: translate(84.0905vw, 100vh) scale(0.6668);
    }
  }
  .snow:nth-child(53) {
    opacity: 0.7261;
    transform: translate(49.5191vw, -10px) scale(0.513);
    animation: fall-53 13s -23s linear infinite;
  }
  @keyframes fall-53 {
    67.484% {
      transform: translate(52.2765vw, 67.484vh) scale(0.513);
    }
    to {
      transform: translate(50.8978vw, 100vh) scale(0.513);
    }
  }
  .snow:nth-child(54) {
    opacity: 0.1782;
    transform: translate(42.7721vw, -10px) scale(0.1015);
    animation: fall-54 13s -2s linear infinite;
  }
  @keyframes fall-54 {
    54.794% {
      transform: translate(33.935vw, 54.794vh) scale(0.1015);
    }
    to {
      transform: translate(38.35355vw, 100vh) scale(0.1015);
    }
  }
  .snow:nth-child(55) {
    opacity: 0.6102;
    transform: translate(72.8633vw, -10px) scale(0.6854);
    animation: fall-55 10s -11s linear infinite;
  }
  @keyframes fall-55 {
    61.467% {
      transform: translate(73.6588vw, 61.467vh) scale(0.6854);
    }
    to {
      transform: translate(73.26105vw, 100vh) scale(0.6854);
    }
  }
  .snow:nth-child(56) {
    opacity: 0.955;
    transform: translate(57.1182vw, -10px) scale(0.7484);
    animation: fall-56 22s -10s linear infinite;
  }
  @keyframes fall-56 {
    30.885% {
      transform: translate(60.844vw, 30.885vh) scale(0.7484);
    }
    to {
      transform: translate(58.9811vw, 100vh) scale(0.7484);
    }
  }
  .snow:nth-child(57) {
    opacity: 0.6074;
    transform: translate(23.7476vw, -10px) scale(0.606);
    animation: fall-57 21s -20s linear infinite;
  }
  @keyframes fall-57 {
    35.399% {
      transform: translate(24.9517vw, 35.399vh) scale(0.606);
    }
    to {
      transform: translate(24.34965vw, 100vh) scale(0.606);
    }
  }
  .snow:nth-child(58) {
    opacity: 0.7157;
    transform: translate(88.3042vw, -10px) scale(0.8856);
    animation: fall-58 23s -26s linear infinite;
  }
  @keyframes fall-58 {
    61.077% {
      transform: translate(95.4641vw, 61.077vh) scale(0.8856);
    }
    to {
      transform: translate(91.88415vw, 100vh) scale(0.8856);
    }
  }
  .snow:nth-child(59) {
    opacity: 0.179;
    transform: translate(96.5157vw, -10px) scale(0.7975);
    animation: fall-59 24s -21s linear infinite;
  }
  @keyframes fall-59 {
    41.123% {
      transform: translate(101.5854vw, 41.123vh) scale(0.7975);
    }
    to {
      transform: translate(99.05055vw, 100vh) scale(0.7975);
    }
  }
  .snow:nth-child(60) {
    opacity: 0.6934;
    transform: translate(23.2523vw, -10px) scale(0.7603);
    animation: fall-60 10s -7s linear infinite;
  }
  @keyframes fall-60 {
    67.825% {
      transform: translate(21.7835vw, 67.825vh) scale(0.7603);
    }
    to {
      transform: translate(22.5179vw, 100vh) scale(0.7603);
    }
  }
  .snow:nth-child(61) {
    opacity: 0.2641;
    transform: translate(13.7746vw, -10px) scale(0.9672);
    animation: fall-61 13s -29s linear infinite;
  }
  @keyframes fall-61 {
    39.362% {
      transform: translate(18.0603vw, 39.362vh) scale(0.9672);
    }
    to {
      transform: translate(15.91745vw, 100vh) scale(0.9672);
    }
  }
  .snow:nth-child(62) {
    opacity: 0.5499;
    transform: translate(16.8045vw, -10px) scale(0.671);
    animation: fall-62 24s -18s linear infinite;
  }
  @keyframes fall-62 {
    67.231% {
      transform: translate(22.7389vw, 67.231vh) scale(0.671);
    }
    to {
      transform: translate(19.7717vw, 100vh) scale(0.671);
    }
  }
  .snow:nth-child(63) {
    opacity: 0.4676;
    transform: translate(84.783vw, -10px) scale(0.0083);
    animation: fall-63 10s -13s linear infinite;
  }
  @keyframes fall-63 {
    63.695% {
      transform: translate(81.1102vw, 63.695vh) scale(0.0083);
    }
    to {
      transform: translate(82.9466vw, 100vh) scale(0.0083);
    }
  }
  .snow:nth-child(64) {
    opacity: 0.1157;
    transform: translate(29.7337vw, -10px) scale(0.5144);
    animation: fall-64 20s -2s linear infinite;
  }
  @keyframes fall-64 {
    48.275% {
      transform: translate(21.1051vw, 48.275vh) scale(0.5144);
    }
    to {
      transform: translate(25.4194vw, 100vh) scale(0.5144);
    }
  }
  .snow:nth-child(65) {
    opacity: 0.6898;
    transform: translate(72.4609vw, -10px) scale(0.9838);
    animation: fall-65 12s -30s linear infinite;
  }
  @keyframes fall-65 {
    66.227% {
      transform: translate(80.8108vw, 66.227vh) scale(0.9838);
    }
    to {
      transform: translate(76.63585vw, 100vh) scale(0.9838);
    }
  }
  .snow:nth-child(66) {
    opacity: 0.9588;
    transform: translate(46.8812vw, -10px) scale(0.6537);
    animation: fall-66 21s -16s linear infinite;
  }
  @keyframes fall-66 {
    36.986% {
      transform: translate(41.5138vw, 36.986vh) scale(0.6537);
    }
    to {
      transform: translate(44.1975vw, 100vh) scale(0.6537);
    }
  }
  .snow:nth-child(67) {
    opacity: 0.1895;
    transform: translate(49.8006vw, -10px) scale(0.697);
    animation: fall-67 15s -26s linear infinite;
  }
  @keyframes fall-67 {
    37.757% {
      transform: translate(50.1941vw, 37.757vh) scale(0.697);
    }
    to {
      transform: translate(49.99735vw, 100vh) scale(0.697);
    }
  }
  .snow:nth-child(68) {
    opacity: 0.1723;
    transform: translate(3.9971vw, -10px) scale(0.5082);
    animation: fall-68 16s -8s linear infinite;
  }
  @keyframes fall-68 {
    69.115% {
      transform: translate(3.113vw, 69.115vh) scale(0.5082);
    }
    to {
      transform: translate(3.55505vw, 100vh) scale(0.5082);
    }
  }
  .snow:nth-child(69) {
    opacity: 0.1011;
    transform: translate(37.4095vw, -10px) scale(0.2945);
    animation: fall-69 21s -26s linear infinite;
  }
  @keyframes fall-69 {
    37.467% {
      transform: translate(28.4607vw, 37.467vh) scale(0.2945);
    }
    to {
      transform: translate(32.9351vw, 100vh) scale(0.2945);
    }
  }
  .snow:nth-child(70) {
    opacity: 0.7418;
    transform: translate(21.8537vw, -10px) scale(0.5491);
    animation: fall-70 24s -7s linear infinite;
  }
  @keyframes fall-70 {
    78.505% {
      transform: translate(13.8958vw, 78.505vh) scale(0.5491);
    }
    to {
      transform: translate(17.87475vw, 100vh) scale(0.5491);
    }
  }
  .snow:nth-child(71) {
    opacity: 0.0723;
    transform: translate(77.6563vw, -10px) scale(0.8784);
    animation: fall-71 20s -25s linear infinite;
  }
  @keyframes fall-71 {
    73.942% {
      transform: translate(81.1462vw, 73.942vh) scale(0.8784);
    }
    to {
      transform: translate(79.40125vw, 100vh) scale(0.8784);
    }
  }
  .snow:nth-child(72) {
    opacity: 0.7566;
    transform: translate(34.0215vw, -10px) scale(0.8845);
    animation: fall-72 15s -14s linear infinite;
  }
  @keyframes fall-72 {
    60.211% {
      transform: translate(36.412vw, 60.211vh) scale(0.8845);
    }
    to {
      transform: translate(35.21675vw, 100vh) scale(0.8845);
    }
  }
  .snow:nth-child(73) {
    opacity: 0.1769;
    transform: translate(65.8668vw, -10px) scale(0.2107);
    animation: fall-73 24s -21s linear infinite;
  }
  @keyframes fall-73 {
    34.019% {
      transform: translate(59.1697vw, 34.019vh) scale(0.2107);
    }
    to {
      transform: translate(62.51825vw, 100vh) scale(0.2107);
    }
  }
  .snow:nth-child(74) {
    opacity: 0.1748;
    transform: translate(71.9911vw, -10px) scale(0.3086);
    animation: fall-74 10s -22s linear infinite;
  }
  @keyframes fall-74 {
    72.977% {
      transform: translate(70.7316vw, 72.977vh) scale(0.3086);
    }
    to {
      transform: translate(71.36135vw, 100vh) scale(0.3086);
    }
  }
  .snow:nth-child(75) {
    opacity: 0.8792;
    transform: translate(18.1549vw, -10px) scale(0.6474);
    animation: fall-75 18s -7s linear infinite;
  }
  @keyframes fall-75 {
    46.92% {
      transform: translate(26.5967vw, 46.92vh) scale(0.6474);
    }
    to {
      transform: translate(22.3758vw, 100vh) scale(0.6474);
    }
  }
  .snow:nth-child(76) {
    opacity: 0.1068;
    transform: translate(4.9143vw, -10px) scale(0.0682);
    animation: fall-76 29s -6s linear infinite;
  }
  @keyframes fall-76 {
    70.701% {
      transform: translate(7.4651vw, 70.701vh) scale(0.0682);
    }
    to {
      transform: translate(6.1897vw, 100vh) scale(0.0682);
    }
  }
  .snow:nth-child(77) {
    opacity: 0.8197;
    transform: translate(68.9581vw, -10px) scale(0.346);
    animation: fall-77 17s -21s linear infinite;
  }
  @keyframes fall-77 {
    75.44% {
      transform: translate(70.2224vw, 75.44vh) scale(0.346);
    }
    to {
      transform: translate(69.59025vw, 100vh) scale(0.346);
    }
  }
  .snow:nth-child(78) {
    opacity: 0.7555;
    transform: translate(40.0854vw, -10px) scale(0.9617);
    animation: fall-78 26s -5s linear infinite;
  }
  @keyframes fall-78 {
    50.39% {
      transform: translate(45.3899vw, 50.39vh) scale(0.9617);
    }
    to {
      transform: translate(42.73765vw, 100vh) scale(0.9617);
    }
  }
  .snow:nth-child(79) {
    opacity: 0.5888;
    transform: translate(12.4835vw, -10px) scale(0.0634);
    animation: fall-79 26s -28s linear infinite;
  }
  @keyframes fall-79 {
    48.831% {
      transform: translate(18.6245vw, 48.831vh) scale(0.0634);
    }
    to {
      transform: translate(15.554vw, 100vh) scale(0.0634);
    }
  }
  .snow:nth-child(80) {
    opacity: 0.4166;
    transform: translate(72.9783vw, -10px) scale(0.7408);
    animation: fall-80 13s -23s linear infinite;
  }
  @keyframes fall-80 {
    79.853% {
      transform: translate(67.0957vw, 79.853vh) scale(0.7408);
    }
    to {
      transform: translate(70.037vw, 100vh) scale(0.7408);
    }
  }
  .snow:nth-child(81) {
    opacity: 0.7346;
    transform: translate(0.7945vw, -10px) scale(0.8819);
    animation: fall-81 21s -29s linear infinite;
  }
  @keyframes fall-81 {
    34.337% {
      transform: translate(0.916vw, 34.337vh) scale(0.8819);
    }
    to {
      transform: translate(0.85525vw, 100vh) scale(0.8819);
    }
  }
  .snow:nth-child(82) {
    opacity: 0.1293;
    transform: translate(23.2462vw, -10px) scale(0.807);
    animation: fall-82 21s -25s linear infinite;
  }
  @keyframes fall-82 {
    31.531% {
      transform: translate(25.5437vw, 31.531vh) scale(0.807);
    }
    to {
      transform: translate(24.39495vw, 100vh) scale(0.807);
    }
  }
  .snow:nth-child(83) {
    opacity: 0.2401;
    transform: translate(84.4261vw, -10px) scale(0.841);
    animation: fall-83 10s -6s linear infinite;
  }
  @keyframes fall-83 {
    41.908% {
      transform: translate(79.575vw, 41.908vh) scale(0.841);
    }
    to {
      transform: translate(82.00055vw, 100vh) scale(0.841);
    }
  }
  .snow:nth-child(84) {
    opacity: 0.6647;
    transform: translate(30.477vw, -10px) scale(0.9367);
    animation: fall-84 11s -28s linear infinite;
  }
  @keyframes fall-84 {
    78.101% {
      transform: translate(28.7642vw, 78.101vh) scale(0.9367);
    }
    to {
      transform: translate(29.6206vw, 100vh) scale(0.9367);
    }
  }
  .snow:nth-child(85) {
    opacity: 0.5241;
    transform: translate(72.0012vw, -10px) scale(0.6428);
    animation: fall-85 30s -26s linear infinite;
  }
  @keyframes fall-85 {
    59.009% {
      transform: translate(64.9917vw, 59.009vh) scale(0.6428);
    }
    to {
      transform: translate(68.49645vw, 100vh) scale(0.6428);
    }
  }
  .snow:nth-child(86) {
    opacity: 0.1578;
    transform: translate(57.8533vw, -10px) scale(0.423);
    animation: fall-86 22s -2s linear infinite;
  }
  @keyframes fall-86 {
    58.394% {
      transform: translate(50.4823vw, 58.394vh) scale(0.423);
    }
    to {
      transform: translate(54.1678vw, 100vh) scale(0.423);
    }
  }
  .snow:nth-child(87) {
    opacity: 0.627;
    transform: translate(97.2982vw, -10px) scale(0.7501);
    animation: fall-87 28s -29s linear infinite;
  }
  @keyframes fall-87 {
    39.69% {
      transform: translate(97.9319vw, 39.69vh) scale(0.7501);
    }
    to {
      transform: translate(97.61505vw, 100vh) scale(0.7501);
    }
  }
  .snow:nth-child(88) {
    opacity: 0.5497;
    transform: translate(56.3851vw, -10px) scale(0.9012);
    animation: fall-88 18s -12s linear infinite;
  }
  @keyframes fall-88 {
    59.811% {
      transform: translate(60.4372vw, 59.811vh) scale(0.9012);
    }
    to {
      transform: translate(58.41115vw, 100vh) scale(0.9012);
    }
  }
  .snow:nth-child(89) {
    opacity: 0.1483;
    transform: translate(40.2996vw, -10px) scale(0.7503);
    animation: fall-89 25s -29s linear infinite;
  }
  @keyframes fall-89 {
    75.279% {
      transform: translate(42.5529vw, 75.279vh) scale(0.7503);
    }
    to {
      transform: translate(41.42625vw, 100vh) scale(0.7503);
    }
  }
  .snow:nth-child(90) {
    opacity: 0.6932;
    transform: translate(71.3287vw, -10px) scale(0.6016);
    animation: fall-90 15s -25s linear infinite;
  }
  @keyframes fall-90 {
    71.142% {
      transform: translate(71.4268vw, 71.142vh) scale(0.6016);
    }
    to {
      transform: translate(71.37775vw, 100vh) scale(0.6016);
    }
  }
  .snow:nth-child(91) {
    opacity: 0.3861;
    transform: translate(71.6669vw, -10px) scale(0.1866);
    animation: fall-91 15s -25s linear infinite;
  }
  @keyframes fall-91 {
    40.121% {
      transform: translate(69.1748vw, 40.121vh) scale(0.1866);
    }
    to {
      transform: translate(70.42085vw, 100vh) scale(0.1866);
    }
  }
  .snow:nth-child(92) {
    opacity: 0.49;
    transform: translate(1.2775vw, -10px) scale(0.8344);
    animation: fall-92 23s -15s linear infinite;
  }
  @keyframes fall-92 {
    61.998% {
      transform: translate(-2.4563vw, 61.998vh) scale(0.8344);
    }
    to {
      transform: translate(-0.5894vw, 100vh) scale(0.8344);
    }
  }
  .snow:nth-child(93) {
    opacity: 0.9349;
    transform: translate(60.7597vw, -10px) scale(0.5436);
    animation: fall-93 15s -30s linear infinite;
  }
  @keyframes fall-93 {
    77.055% {
      transform: translate(53.3344vw, 77.055vh) scale(0.5436);
    }
    to {
      transform: translate(57.04705vw, 100vh) scale(0.5436);
    }
  }
  .snow:nth-child(94) {
    opacity: 0.7481;
    transform: translate(40.219vw, -10px) scale(0.2993);
    animation: fall-94 24s -3s linear infinite;
  }
  @keyframes fall-94 {
    76.381% {
      transform: translate(33.8704vw, 76.381vh) scale(0.2993);
    }
    to {
      transform: translate(37.0447vw, 100vh) scale(0.2993);
    }
  }
  .snow:nth-child(95) {
    opacity: 0.4673;
    transform: translate(14.5947vw, -10px) scale(0.158);
    animation: fall-95 29s -11s linear infinite;
  }
  @keyframes fall-95 {
    53.593% {
      transform: translate(14.6571vw, 53.593vh) scale(0.158);
    }
    to {
      transform: translate(14.6259vw, 100vh) scale(0.158);
    }
  }
  .snow:nth-child(96) {
    opacity: 0.6708;
    transform: translate(0.534vw, -10px) scale(0.3385);
    animation: fall-96 12s -7s linear infinite;
  }
  @keyframes fall-96 {
    36.182% {
      transform: translate(5.0952vw, 36.182vh) scale(0.3385);
    }
    to {
      transform: translate(2.8146vw, 100vh) scale(0.3385);
    }
  }
  .snow:nth-child(97) {
    opacity: 0.0533;
    transform: translate(71.6522vw, -10px) scale(0.9225);
    animation: fall-97 13s -29s linear infinite;
  }
  @keyframes fall-97 {
    36.804% {
      transform: translate(67.3119vw, 36.804vh) scale(0.9225);
    }
    to {
      transform: translate(69.48205vw, 100vh) scale(0.9225);
    }
  }
  .snow:nth-child(98) {
    opacity: 0.0639;
    transform: translate(3.7673vw, -10px) scale(0.3814);
    animation: fall-98 10s -22s linear infinite;
  }
  @keyframes fall-98 {
    58.754% {
      transform: translate(5.3395vw, 58.754vh) scale(0.3814);
    }
    to {
      transform: translate(4.5534vw, 100vh) scale(0.3814);
    }
  }
  .snow:nth-child(99) {
    opacity: 0.1609;
    transform: translate(45.8613vw, -10px) scale(0.2064);
    animation: fall-99 17s -12s linear infinite;
  }
  @keyframes fall-99 {
    37.117% {
      transform: translate(48.7118vw, 37.117vh) scale(0.2064);
    }
    to {
      transform: translate(47.28655vw, 100vh) scale(0.2064);
    }
  }
  .snow:nth-child(100) {
    opacity: 0.6392;
    transform: translate(48.7262vw, -10px) scale(0.2292);
    animation: fall-100 19s -23s linear infinite;
  }
  @keyframes fall-100 {
    34.601% {
      transform: translate(54.4982vw, 34.601vh) scale(0.2292);
    }
    to {
      transform: translate(51.6122vw, 100vh) scale(0.2292);
    }
  }
  .snow:nth-child(101) {
    opacity: 0.3502;
    transform: translate(2.6548vw, -10px) scale(0.5926);
    animation: fall-101 30s -25s linear infinite;
  }
  @keyframes fall-101 {
    75.155% {
      transform: translate(0.6149vw, 75.155vh) scale(0.5926);
    }
    to {
      transform: translate(1.63485vw, 100vh) scale(0.5926);
    }
  }
  .snow:nth-child(102) {
    opacity: 0.79;
    transform: translate(38.4168vw, -10px) scale(0.1899);
    animation: fall-102 10s -28s linear infinite;
  }
  @keyframes fall-102 {
    66.013% {
      transform: translate(41.0652vw, 66.013vh) scale(0.1899);
    }
    to {
      transform: translate(39.741vw, 100vh) scale(0.1899);
    }
  }
  .snow:nth-child(103) {
    opacity: 0.0051;
    transform: translate(22.2128vw, -10px) scale(0.3594);
    animation: fall-103 15s -17s linear infinite;
  }
  @keyframes fall-103 {
    71.878% {
      transform: translate(27.0883vw, 71.878vh) scale(0.3594);
    }
    to {
      transform: translate(24.65055vw, 100vh) scale(0.3594);
    }
  }
  .snow:nth-child(104) {
    opacity: 0.9905;
    transform: translate(56.9917vw, -10px) scale(0.6594);
    animation: fall-104 17s -25s linear infinite;
  }
  @keyframes fall-104 {
    35.959% {
      transform: translate(54.0138vw, 35.959vh) scale(0.6594);
    }
    to {
      transform: translate(55.50275vw, 100vh) scale(0.6594);
    }
  }
  .snow:nth-child(105) {
    opacity: 0.2736;
    transform: translate(27.5315vw, -10px) scale(0.7569);
    animation: fall-105 19s -8s linear infinite;
  }
  @keyframes fall-105 {
    40.059% {
      transform: translate(34.474vw, 40.059vh) scale(0.7569);
    }
    to {
      transform: translate(31.00275vw, 100vh) scale(0.7569);
    }
  }
  .snow:nth-child(106) {
    opacity: 0.7051;
    transform: translate(24.295vw, -10px) scale(0.7343);
    animation: fall-106 25s -8s linear infinite;
  }
  @keyframes fall-106 {
    58.877% {
      transform: translate(15.5066vw, 58.877vh) scale(0.7343);
    }
    to {
      transform: translate(19.9008vw, 100vh) scale(0.7343);
    }
  }
  .snow:nth-child(107) {
    opacity: 0.1504;
    transform: translate(47.3463vw, -10px) scale(0.4576);
    animation: fall-107 21s -9s linear infinite;
  }
  @keyframes fall-107 {
    43.137% {
      transform: translate(46.9348vw, 43.137vh) scale(0.4576);
    }
    to {
      transform: translate(47.14055vw, 100vh) scale(0.4576);
    }
  }
  .snow:nth-child(108) {
    opacity: 0.8251;
    transform: translate(4.2544vw, -10px) scale(0.2112);
    animation: fall-108 25s -10s linear infinite;
  }
  @keyframes fall-108 {
    68.529% {
      transform: translate(2.4477vw, 68.529vh) scale(0.2112);
    }
    to {
      transform: translate(3.35105vw, 100vh) scale(0.2112);
    }
  }
  .snow:nth-child(109) {
    opacity: 0.8748;
    transform: translate(36.4362vw, -10px) scale(0.1726);
    animation: fall-109 29s -16s linear infinite;
  }
  @keyframes fall-109 {
    44.136% {
      transform: translate(39.1812vw, 44.136vh) scale(0.1726);
    }
    to {
      transform: translate(37.8087vw, 100vh) scale(0.1726);
    }
  }
  .snow:nth-child(110) {
    opacity: 0.3664;
    transform: translate(65.4853vw, -10px) scale(0.1761);
    animation: fall-110 10s -29s linear infinite;
  }
  @keyframes fall-110 {
    46.738% {
      transform: translate(73.5834vw, 46.738vh) scale(0.1761);
    }
    to {
      transform: translate(69.53435vw, 100vh) scale(0.1761);
    }
  }
  .snow:nth-child(111) {
    opacity: 0.1164;
    transform: translate(27.8757vw, -10px) scale(0.615);
    animation: fall-111 22s -6s linear infinite;
  }
  @keyframes fall-111 {
    41.267% {
      transform: translate(25.1025vw, 41.267vh) scale(0.615);
    }
    to {
      transform: translate(26.4891vw, 100vh) scale(0.615);
    }
  }
  .snow:nth-child(112) {
    opacity: 0.5909;
    transform: translate(72.0062vw, -10px) scale(0.3956);
    animation: fall-112 25s -4s linear infinite;
  }
  @keyframes fall-112 {
    69.325% {
      transform: translate(73.3884vw, 69.325vh) scale(0.3956);
    }
    to {
      transform: translate(72.6973vw, 100vh) scale(0.3956);
    }
  }
  .snow:nth-child(113) {
    opacity: 0.8104;
    transform: translate(20.743vw, -10px) scale(0.4375);
    animation: fall-113 28s -25s linear infinite;
  }
  @keyframes fall-113 {
    51.85% {
      transform: translate(16.7171vw, 51.85vh) scale(0.4375);
    }
    to {
      transform: translate(18.73005vw, 100vh) scale(0.4375);
    }
  }
  .snow:nth-child(114) {
    opacity: 0.7904;
    transform: translate(37.8861vw, -10px) scale(0.3922);
    animation: fall-114 18s -1s linear infinite;
  }
  @keyframes fall-114 {
    48.525% {
      transform: translate(28.495vw, 48.525vh) scale(0.3922);
    }
    to {
      transform: translate(33.19055vw, 100vh) scale(0.3922);
    }
  }
  .snow:nth-child(115) {
    opacity: 0.9131;
    transform: translate(31.6722vw, -10px) scale(0.5764);
    animation: fall-115 11s -7s linear infinite;
  }
  @keyframes fall-115 {
    33.477% {
      transform: translate(28.5137vw, 33.477vh) scale(0.5764);
    }
    to {
      transform: translate(30.09295vw, 100vh) scale(0.5764);
    }
  }
  .snow:nth-child(116) {
    opacity: 0.2176;
    transform: translate(10.2651vw, -10px) scale(0.6984);
    animation: fall-116 25s -5s linear infinite;
  }
  @keyframes fall-116 {
    74.693% {
      transform: translate(11.5891vw, 74.693vh) scale(0.6984);
    }
    to {
      transform: translate(10.9271vw, 100vh) scale(0.6984);
    }
  }
  .snow:nth-child(117) {
    opacity: 0.2054;
    transform: translate(37.2696vw, -10px) scale(0.2131);
    animation: fall-117 15s -2s linear infinite;
  }
  @keyframes fall-117 {
    53.436% {
      transform: translate(37.1821vw, 53.436vh) scale(0.2131);
    }
    to {
      transform: translate(37.22585vw, 100vh) scale(0.2131);
    }
  }
  .snow:nth-child(118) {
    opacity: 0.3375;
    transform: translate(76.5479vw, -10px) scale(0.7908);
    animation: fall-118 27s -20s linear infinite;
  }
  @keyframes fall-118 {
    54.72% {
      transform: translate(84.6303vw, 54.72vh) scale(0.7908);
    }
    to {
      transform: translate(80.5891vw, 100vh) scale(0.7908);
    }
  }
  .snow:nth-child(119) {
    opacity: 0.7359;
    transform: translate(28.6654vw, -10px) scale(0.072);
    animation: fall-119 26s -14s linear infinite;
  }
  @keyframes fall-119 {
    69.795% {
      transform: translate(37.7058vw, 69.795vh) scale(0.072);
    }
    to {
      transform: translate(33.1856vw, 100vh) scale(0.072);
    }
  }
  .snow:nth-child(120) {
    opacity: 0.6505;
    transform: translate(11.1748vw, -10px) scale(0.6628);
    animation: fall-120 25s -9s linear infinite;
  }
  @keyframes fall-120 {
    39.304% {
      transform: translate(11.9502vw, 39.304vh) scale(0.6628);
    }
    to {
      transform: translate(11.5625vw, 100vh) scale(0.6628);
    }
  }
  .snow:nth-child(121) {
    opacity: 0.4071;
    transform: translate(38.7087vw, -10px) scale(0.7593);
    animation: fall-121 29s -6s linear infinite;
  }
  @keyframes fall-121 {
    68.133% {
      transform: translate(43.4109vw, 68.133vh) scale(0.7593);
    }
    to {
      transform: translate(41.0598vw, 100vh) scale(0.7593);
    }
  }
  .snow:nth-child(122) {
    opacity: 0.1783;
    transform: translate(33.973vw, -10px) scale(0.174);
    animation: fall-122 10s -30s linear infinite;
  }
  @keyframes fall-122 {
    58.571% {
      transform: translate(26.0581vw, 58.571vh) scale(0.174);
    }
    to {
      transform: translate(30.01555vw, 100vh) scale(0.174);
    }
  }
  .snow:nth-child(123) {
    opacity: 0.6527;
    transform: translate(71.0175vw, -10px) scale(0.3239);
    animation: fall-123 14s -12s linear infinite;
  }
  @keyframes fall-123 {
    74.221% {
      transform: translate(67.415vw, 74.221vh) scale(0.3239);
    }
    to {
      transform: translate(69.21625vw, 100vh) scale(0.3239);
    }
  }
  .snow:nth-child(124) {
    opacity: 0.0404;
    transform: translate(3.2202vw, -10px) scale(0.6453);
    animation: fall-124 17s -19s linear infinite;
  }
  @keyframes fall-124 {
    41.893% {
      transform: translate(1.3023vw, 41.893vh) scale(0.6453);
    }
    to {
      transform: translate(2.26125vw, 100vh) scale(0.6453);
    }
  }
  .snow:nth-child(125) {
    opacity: 0.4253;
    transform: translate(1.1669vw, -10px) scale(0.9597);
    animation: fall-125 23s -10s linear infinite;
  }
  @keyframes fall-125 {
    42.823% {
      transform: translate(7.1981vw, 42.823vh) scale(0.9597);
    }
    to {
      transform: translate(4.1825vw, 100vh) scale(0.9597);
    }
  }
  .snow:nth-child(126) {
    opacity: 0.3891;
    transform: translate(3.6274vw, -10px) scale(0.6552);
    animation: fall-126 13s -3s linear infinite;
  }
  @keyframes fall-126 {
    60.161% {
      transform: translate(12.7454vw, 60.161vh) scale(0.6552);
    }
    to {
      transform: translate(8.1864vw, 100vh) scale(0.6552);
    }
  }
  .snow:nth-child(127) {
    opacity: 0.8065;
    transform: translate(43.5849vw, -10px) scale(0.9563);
    animation: fall-127 25s -9s linear infinite;
  }
  @keyframes fall-127 {
    70.464% {
      transform: translate(36.0533vw, 70.464vh) scale(0.9563);
    }
    to {
      transform: translate(39.8191vw, 100vh) scale(0.9563);
    }
  }
  .snow:nth-child(128) {
    opacity: 0.7972;
    transform: translate(14.2525vw, -10px) scale(0.7574);
    animation: fall-128 27s -12s linear infinite;
  }
  @keyframes fall-128 {
    58.533% {
      transform: translate(22.4367vw, 58.533vh) scale(0.7574);
    }
    to {
      transform: translate(18.3446vw, 100vh) scale(0.7574);
    }
  }
  .snow:nth-child(129) {
    opacity: 0.0672;
    transform: translate(38.9127vw, -10px) scale(0.8559);
    animation: fall-129 25s -13s linear infinite;
  }
  @keyframes fall-129 {
    73.994% {
      transform: translate(32.3217vw, 73.994vh) scale(0.8559);
    }
    to {
      transform: translate(35.6172vw, 100vh) scale(0.8559);
    }
  }
  .snow:nth-child(130) {
    opacity: 0.9458;
    transform: translate(67.2709vw, -10px) scale(0.8411);
    animation: fall-130 16s -8s linear infinite;
  }
  @keyframes fall-130 {
    54.928% {
      transform: translate(62.4187vw, 54.928vh) scale(0.8411);
    }
    to {
      transform: translate(64.8448vw, 100vh) scale(0.8411);
    }
  }
  .snow:nth-child(131) {
    opacity: 0.584;
    transform: translate(5.468vw, -10px) scale(0.7785);
    animation: fall-131 23s -26s linear infinite;
  }
  @keyframes fall-131 {
    61.2% {
      transform: translate(3.4936vw, 61.2vh) scale(0.7785);
    }
    to {
      transform: translate(4.4808vw, 100vh) scale(0.7785);
    }
  }
  .snow:nth-child(132) {
    opacity: 0.9313;
    transform: translate(24.344vw, -10px) scale(0.4334);
    animation: fall-132 15s -3s linear infinite;
  }
  @keyframes fall-132 {
    79.552% {
      transform: translate(27.3624vw, 79.552vh) scale(0.4334);
    }
    to {
      transform: translate(25.8532vw, 100vh) scale(0.4334);
    }
  }
  .snow:nth-child(133) {
    opacity: 0.5135;
    transform: translate(97.8882vw, -10px) scale(0.8416);
    animation: fall-133 26s -13s linear infinite;
  }
  @keyframes fall-133 {
    62.65% {
      transform: translate(101.8563vw, 62.65vh) scale(0.8416);
    }
    to {
      transform: translate(99.87225vw, 100vh) scale(0.8416);
    }
  }
  .snow:nth-child(134) {
    opacity: 0.3454;
    transform: translate(31.6422vw, -10px) scale(0.6716);
    animation: fall-134 21s -26s linear infinite;
  }
  @keyframes fall-134 {
    41.056% {
      transform: translate(38.0019vw, 41.056vh) scale(0.6716);
    }
    to {
      transform: translate(34.82205vw, 100vh) scale(0.6716);
    }
  }
  .snow:nth-child(135) {
    opacity: 0.9599;
    transform: translate(68.3643vw, -10px) scale(0.4488);
    animation: fall-135 17s -4s linear infinite;
  }
  @keyframes fall-135 {
    62.815% {
      transform: translate(66.2816vw, 62.815vh) scale(0.4488);
    }
    to {
      transform: translate(67.32295vw, 100vh) scale(0.4488);
    }
  }
  .snow:nth-child(136) {
    opacity: 0.4144;
    transform: translate(78.7456vw, -10px) scale(0.7146);
    animation: fall-136 12s -16s linear infinite;
  }
  @keyframes fall-136 {
    65.689% {
      transform: translate(80.4462vw, 65.689vh) scale(0.7146);
    }
    to {
      transform: translate(79.5959vw, 100vh) scale(0.7146);
    }
  }
  .snow:nth-child(137) {
    opacity: 0.6879;
    transform: translate(63.7626vw, -10px) scale(0.3984);
    animation: fall-137 17s -28s linear infinite;
  }
  @keyframes fall-137 {
    31.559% {
      transform: translate(63.2182vw, 31.559vh) scale(0.3984);
    }
    to {
      transform: translate(63.4904vw, 100vh) scale(0.3984);
    }
  }
  .snow:nth-child(138) {
    opacity: 0.1324;
    transform: translate(69.6138vw, -10px) scale(0.1426);
    animation: fall-138 13s -22s linear infinite;
  }
  @keyframes fall-138 {
    76.559% {
      transform: translate(60.4533vw, 76.559vh) scale(0.1426);
    }
    to {
      transform: translate(65.03355vw, 100vh) scale(0.1426);
    }
  }
  .snow:nth-child(139) {
    opacity: 0.4548;
    transform: translate(84.3085vw, -10px) scale(0.6359);
    animation: fall-139 29s -14s linear infinite;
  }
  @keyframes fall-139 {
    78.069% {
      transform: translate(83.2553vw, 78.069vh) scale(0.6359);
    }
    to {
      transform: translate(83.7819vw, 100vh) scale(0.6359);
    }
  }
  .snow:nth-child(140) {
    opacity: 0.0743;
    transform: translate(13.2193vw, -10px) scale(0.5586);
    animation: fall-140 12s -20s linear infinite;
  }
  @keyframes fall-140 {
    38.334% {
      transform: translate(18.0152vw, 38.334vh) scale(0.5586);
    }
    to {
      transform: translate(15.61725vw, 100vh) scale(0.5586);
    }
  }
  .snow:nth-child(141) {
    opacity: 0.2379;
    transform: translate(12.0771vw, -10px) scale(0.1106);
    animation: fall-141 11s -18s linear infinite;
  }
  @keyframes fall-141 {
    48.227% {
      transform: translate(12.1182vw, 48.227vh) scale(0.1106);
    }
    to {
      transform: translate(12.09765vw, 100vh) scale(0.1106);
    }
  }
  .snow:nth-child(142) {
    opacity: 0.0073;
    transform: translate(77.0011vw, -10px) scale(0.0157);
    animation: fall-142 17s -25s linear infinite;
  }
  @keyframes fall-142 {
    42.435% {
      transform: translate(77.6425vw, 42.435vh) scale(0.0157);
    }
    to {
      transform: translate(77.3218vw, 100vh) scale(0.0157);
    }
  }
  .snow:nth-child(143) {
    opacity: 0.5609;
    transform: translate(87.2096vw, -10px) scale(0.6724);
    animation: fall-143 13s -21s linear infinite;
  }
  @keyframes fall-143 {
    69.074% {
      transform: translate(83.379vw, 69.074vh) scale(0.6724);
    }
    to {
      transform: translate(85.2943vw, 100vh) scale(0.6724);
    }
  }
  .snow:nth-child(144) {
    opacity: 0.3406;
    transform: translate(4.4838vw, -10px) scale(0.169);
    animation: fall-144 13s -14s linear infinite;
  }
  @keyframes fall-144 {
    63.252% {
      transform: translate(-2.8056vw, 63.252vh) scale(0.169);
    }
    to {
      transform: translate(0.8391vw, 100vh) scale(0.169);
    }
  }
  .snow:nth-child(145) {
    opacity: 0.4532;
    transform: translate(37.2661vw, -10px) scale(0.0599);
    animation: fall-145 17s -16s linear infinite;
  }
  @keyframes fall-145 {
    45.605% {
      transform: translate(39.4125vw, 45.605vh) scale(0.0599);
    }
    to {
      transform: translate(38.3393vw, 100vh) scale(0.0599);
    }
  }
  .snow:nth-child(146) {
    opacity: 0.4043;
    transform: translate(65.6337vw, -10px) scale(0.1677);
    animation: fall-146 25s -13s linear infinite;
  }
  @keyframes fall-146 {
    74.243% {
      transform: translate(62.5521vw, 74.243vh) scale(0.1677);
    }
    to {
      transform: translate(64.0929vw, 100vh) scale(0.1677);
    }
  }
  .snow:nth-child(147) {
    opacity: 0.3397;
    transform: translate(90.2781vw, -10px) scale(0.6289);
    animation: fall-147 18s -23s linear infinite;
  }
  @keyframes fall-147 {
    59.571% {
      transform: translate(82.9516vw, 59.571vh) scale(0.6289);
    }
    to {
      transform: translate(86.61485vw, 100vh) scale(0.6289);
    }
  }
  .snow:nth-child(148) {
    opacity: 0.5343;
    transform: translate(56.4466vw, -10px) scale(0.2584);
    animation: fall-148 15s -28s linear infinite;
  }
  @keyframes fall-148 {
    63.163% {
      transform: translate(56.4374vw, 63.163vh) scale(0.2584);
    }
    to {
      transform: translate(56.442vw, 100vh) scale(0.2584);
    }
  }
  .snow:nth-child(149) {
    opacity: 0.7904;
    transform: translate(77.5525vw, -10px) scale(0.9547);
    animation: fall-149 18s -23s linear infinite;
  }
  @keyframes fall-149 {
    38.198% {
      transform: translate(71.1137vw, 38.198vh) scale(0.9547);
    }
    to {
      transform: translate(74.3331vw, 100vh) scale(0.9547);
    }
  }
  .snow:nth-child(150) {
    opacity: 0.0407;
    transform: translate(26.8996vw, -10px) scale(0.646);
    animation: fall-150 11s -4s linear infinite;
  }
  @keyframes fall-150 {
    66.378% {
      transform: translate(31.9393vw, 66.378vh) scale(0.646);
    }
    to {
      transform: translate(29.41945vw, 100vh) scale(0.646);
    }
  }
  .snow:nth-child(151) {
    opacity: 0.1415;
    transform: translate(77.6341vw, -10px) scale(0.766);
    animation: fall-151 25s -1s linear infinite;
  }
  @keyframes fall-151 {
    53.842% {
      transform: translate(71.7799vw, 53.842vh) scale(0.766);
    }
    to {
      transform: translate(74.707vw, 100vh) scale(0.766);
    }
  }
  .snow:nth-child(152) {
    opacity: 0.2495;
    transform: translate(29.4745vw, -10px) scale(0.4193);
    animation: fall-152 17s -19s linear infinite;
  }
  @keyframes fall-152 {
    65.537% {
      transform: translate(21.7998vw, 65.537vh) scale(0.4193);
    }
    to {
      transform: translate(25.63715vw, 100vh) scale(0.4193);
    }
  }
  .snow:nth-child(153) {
    opacity: 0.9991;
    transform: translate(52.2415vw, -10px) scale(0.0176);
    animation: fall-153 14s -14s linear infinite;
  }
  @keyframes fall-153 {
    56.603% {
      transform: translate(46.6465vw, 56.603vh) scale(0.0176);
    }
    to {
      transform: translate(49.444vw, 100vh) scale(0.0176);
    }
  }
  .snow:nth-child(154) {
    opacity: 0.1351;
    transform: translate(30.2127vw, -10px) scale(0.7259);
    animation: fall-154 10s -17s linear infinite;
  }
  @keyframes fall-154 {
    77.761% {
      transform: translate(23.0459vw, 77.761vh) scale(0.7259);
    }
    to {
      transform: translate(26.6293vw, 100vh) scale(0.7259);
    }
  }
  .snow:nth-child(155) {
    opacity: 0.9015;
    transform: translate(56.8102vw, -10px) scale(0.2901);
    animation: fall-155 12s -16s linear infinite;
  }
  @keyframes fall-155 {
    74.804% {
      transform: translate(51.4844vw, 74.804vh) scale(0.2901);
    }
    to {
      transform: translate(54.1473vw, 100vh) scale(0.2901);
    }
  }
  .snow:nth-child(156) {
    opacity: 0.2868;
    transform: translate(52.6434vw, -10px) scale(0.3214);
    animation: fall-156 19s -13s linear infinite;
  }
  @keyframes fall-156 {
    39.619% {
      transform: translate(49.1483vw, 39.619vh) scale(0.3214);
    }
    to {
      transform: translate(50.89585vw, 100vh) scale(0.3214);
    }
  }
  .snow:nth-child(157) {
    opacity: 0.0173;
    transform: translate(87.6809vw, -10px) scale(0.5497);
    animation: fall-157 29s -2s linear infinite;
  }
  @keyframes fall-157 {
    34.003% {
      transform: translate(80.0406vw, 34.003vh) scale(0.5497);
    }
    to {
      transform: translate(83.86075vw, 100vh) scale(0.5497);
    }
  }
  .snow:nth-child(158) {
    opacity: 0.387;
    transform: translate(95.9039vw, -10px) scale(0.6539);
    animation: fall-158 25s -4s linear infinite;
  }
  @keyframes fall-158 {
    74.588% {
      transform: translate(95.4084vw, 74.588vh) scale(0.6539);
    }
    to {
      transform: translate(95.65615vw, 100vh) scale(0.6539);
    }
  }
  .snow:nth-child(159) {
    opacity: 0.8457;
    transform: translate(8.4514vw, -10px) scale(0.8252);
    animation: fall-159 26s -19s linear infinite;
  }
  @keyframes fall-159 {
    64.936% {
      transform: translate(16.4572vw, 64.936vh) scale(0.8252);
    }
    to {
      transform: translate(12.4543vw, 100vh) scale(0.8252);
    }
  }
  .snow:nth-child(160) {
    opacity: 0.786;
    transform: translate(64.3028vw, -10px) scale(0.1342);
    animation: fall-160 12s -18s linear infinite;
  }
  @keyframes fall-160 {
    47.61% {
      transform: translate(63.3332vw, 47.61vh) scale(0.1342);
    }
    to {
      transform: translate(63.818vw, 100vh) scale(0.1342);
    }
  }
  .snow:nth-child(161) {
    opacity: 0.9169;
    transform: translate(92.902vw, -10px) scale(0.9122);
    animation: fall-161 18s -22s linear infinite;
  }
  @keyframes fall-161 {
    73.758% {
      transform: translate(96.917vw, 73.758vh) scale(0.9122);
    }
    to {
      transform: translate(94.9095vw, 100vh) scale(0.9122);
    }
  }
  .snow:nth-child(162) {
    opacity: 0.132;
    transform: translate(44.291vw, -10px) scale(0.4068);
    animation: fall-162 16s -12s linear infinite;
  }
  @keyframes fall-162 {
    67.902% {
      transform: translate(53.7656vw, 67.902vh) scale(0.4068);
    }
    to {
      transform: translate(49.0283vw, 100vh) scale(0.4068);
    }
  }
  .snow:nth-child(163) {
    opacity: 0.824;
    transform: translate(29.3363vw, -10px) scale(0.3065);
    animation: fall-163 29s -7s linear infinite;
  }
  @keyframes fall-163 {
    66.571% {
      transform: translate(24.0299vw, 66.571vh) scale(0.3065);
    }
    to {
      transform: translate(26.6831vw, 100vh) scale(0.3065);
    }
  }
  .snow:nth-child(164) {
    opacity: 0.1389;
    transform: translate(41.3723vw, -10px) scale(0.511);
    animation: fall-164 25s -20s linear infinite;
  }
  @keyframes fall-164 {
    63.684% {
      transform: translate(41.7256vw, 63.684vh) scale(0.511);
    }
    to {
      transform: translate(41.54895vw, 100vh) scale(0.511);
    }
  }
  .snow:nth-child(165) {
    opacity: 0.5942;
    transform: translate(39.3677vw, -10px) scale(0.609);
    animation: fall-165 28s -6s linear infinite;
  }
  @keyframes fall-165 {
    68.809% {
      transform: translate(47.872vw, 68.809vh) scale(0.609);
    }
    to {
      transform: translate(43.61985vw, 100vh) scale(0.609);
    }
  }
  .snow:nth-child(166) {
    opacity: 0.1006;
    transform: translate(91.674vw, -10px) scale(0.8912);
    animation: fall-166 18s -26s linear infinite;
  }
  @keyframes fall-166 {
    46.676% {
      transform: translate(92.4574vw, 46.676vh) scale(0.8912);
    }
    to {
      transform: translate(92.0657vw, 100vh) scale(0.8912);
    }
  }
  .snow:nth-child(167) {
    opacity: 0.8314;
    transform: translate(34.7697vw, -10px) scale(0.5527);
    animation: fall-167 24s -8s linear infinite;
  }
  @keyframes fall-167 {
    33.32% {
      transform: translate(31.8984vw, 33.32vh) scale(0.5527);
    }
    to {
      transform: translate(33.33405vw, 100vh) scale(0.5527);
    }
  }
  .snow:nth-child(168) {
    opacity: 0.9818;
    transform: translate(71.31vw, -10px) scale(0.541);
    animation: fall-168 11s -17s linear infinite;
  }
  @keyframes fall-168 {
    36.954% {
      transform: translate(70.2886vw, 36.954vh) scale(0.541);
    }
    to {
      transform: translate(70.7993vw, 100vh) scale(0.541);
    }
  }
  .snow:nth-child(169) {
    opacity: 0.5241;
    transform: translate(73.6035vw, -10px) scale(0.4447);
    animation: fall-169 17s -22s linear infinite;
  }
  @keyframes fall-169 {
    79.173% {
      transform: translate(78.0866vw, 79.173vh) scale(0.4447);
    }
    to {
      transform: translate(75.84505vw, 100vh) scale(0.4447);
    }
  }
  .snow:nth-child(170) {
    opacity: 0.8837;
    transform: translate(67.756vw, -10px) scale(0.1774);
    animation: fall-170 28s -25s linear infinite;
  }
  @keyframes fall-170 {
    36.302% {
      transform: translate(68.9904vw, 36.302vh) scale(0.1774);
    }
    to {
      transform: translate(68.3732vw, 100vh) scale(0.1774);
    }
  }
  .snow:nth-child(171) {
    opacity: 0.0374;
    transform: translate(18.3555vw, -10px) scale(0.5021);
    animation: fall-171 26s -4s linear infinite;
  }
  @keyframes fall-171 {
    51.791% {
      transform: translate(16.3555vw, 51.791vh) scale(0.5021);
    }
    to {
      transform: translate(17.3555vw, 100vh) scale(0.5021);
    }
  }
  .snow:nth-child(172) {
    opacity: 0.7158;
    transform: translate(19.2046vw, -10px) scale(0.3434);
    animation: fall-172 26s -4s linear infinite;
  }
  @keyframes fall-172 {
    77.881% {
      transform: translate(18.7173vw, 77.881vh) scale(0.3434);
    }
    to {
      transform: translate(18.96095vw, 100vh) scale(0.3434);
    }
  }
  .snow:nth-child(173) {
    opacity: 0.9164;
    transform: translate(32.6668vw, -10px) scale(0.0773);
    animation: fall-173 27s -11s linear infinite;
  }
  @keyframes fall-173 {
    51.938% {
      transform: translate(27.1831vw, 51.938vh) scale(0.0773);
    }
    to {
      transform: translate(29.92495vw, 100vh) scale(0.0773);
    }
  }
  .snow:nth-child(174) {
    opacity: 0.1234;
    transform: translate(60.0775vw, -10px) scale(0.3369);
    animation: fall-174 18s -17s linear infinite;
  }
  @keyframes fall-174 {
    48.201% {
      transform: translate(64.0721vw, 48.201vh) scale(0.3369);
    }
    to {
      transform: translate(62.0748vw, 100vh) scale(0.3369);
    }
  }
  .snow:nth-child(175) {
    opacity: 0.4805;
    transform: translate(85.5683vw, -10px) scale(0.2345);
    animation: fall-175 29s -14s linear infinite;
  }
  @keyframes fall-175 {
    36.834% {
      transform: translate(94.3782vw, 36.834vh) scale(0.2345);
    }
    to {
      transform: translate(89.97325vw, 100vh) scale(0.2345);
    }
  }
  .snow:nth-child(176) {
    opacity: 0.2404;
    transform: translate(4.9986vw, -10px) scale(0.7269);
    animation: fall-176 30s -11s linear infinite;
  }
  @keyframes fall-176 {
    55.271% {
      transform: translate(1.2351vw, 55.271vh) scale(0.7269);
    }
    to {
      transform: translate(3.11685vw, 100vh) scale(0.7269);
    }
  }
  .snow:nth-child(177) {
    opacity: 0.0118;
    transform: translate(9.6854vw, -10px) scale(0.1993);
    animation: fall-177 12s -18s linear infinite;
  }
  @keyframes fall-177 {
    48.082% {
      transform: translate(7.0061vw, 48.082vh) scale(0.1993);
    }
    to {
      transform: translate(8.34575vw, 100vh) scale(0.1993);
    }
  }
  .snow:nth-child(178) {
    opacity: 0.0403;
    transform: translate(85.2292vw, -10px) scale(0.5034);
    animation: fall-178 27s -11s linear infinite;
  }
  @keyframes fall-178 {
    46.075% {
      transform: translate(76.0734vw, 46.075vh) scale(0.5034);
    }
    to {
      transform: translate(80.6513vw, 100vh) scale(0.5034);
    }
  }
  .snow:nth-child(179) {
    opacity: 0.3806;
    transform: translate(22.3595vw, -10px) scale(0.4095);
    animation: fall-179 24s -3s linear infinite;
  }
  @keyframes fall-179 {
    31.738% {
      transform: translate(27.9806vw, 31.738vh) scale(0.4095);
    }
    to {
      transform: translate(25.17005vw, 100vh) scale(0.4095);
    }
  }
  .snow:nth-child(180) {
    opacity: 0.7517;
    transform: translate(40.2565vw, -10px) scale(0.2329);
    animation: fall-180 11s -23s linear infinite;
  }
  @keyframes fall-180 {
    44.625% {
      transform: translate(37.7866vw, 44.625vh) scale(0.2329);
    }
    to {
      transform: translate(39.02155vw, 100vh) scale(0.2329);
    }
  }
  .snow:nth-child(181) {
    opacity: 0.5736;
    transform: translate(36.6871vw, -10px) scale(0.6329);
    animation: fall-181 24s -19s linear infinite;
  }
  @keyframes fall-181 {
    34.873% {
      transform: translate(36.9514vw, 34.873vh) scale(0.6329);
    }
    to {
      transform: translate(36.81925vw, 100vh) scale(0.6329);
    }
  }
  .snow:nth-child(182) {
    opacity: 0.9935;
    transform: translate(20.2521vw, -10px) scale(0.8743);
    animation: fall-182 23s -28s linear infinite;
  }
  @keyframes fall-182 {
    63.648% {
      transform: translate(26.7808vw, 63.648vh) scale(0.8743);
    }
    to {
      transform: translate(23.51645vw, 100vh) scale(0.8743);
    }
  }
  .snow:nth-child(183) {
    opacity: 0.7689;
    transform: translate(87.5712vw, -10px) scale(0.0267);
    animation: fall-183 16s -9s linear infinite;
  }
  @keyframes fall-183 {
    72.513% {
      transform: translate(94.597vw, 72.513vh) scale(0.0267);
    }
    to {
      transform: translate(91.0841vw, 100vh) scale(0.0267);
    }
  }
  .snow:nth-child(184) {
    opacity: 0.2486;
    transform: translate(89.3673vw, -10px) scale(0.8799);
    animation: fall-184 15s -25s linear infinite;
  }
  @keyframes fall-184 {
    60.683% {
      transform: translate(86.6188vw, 60.683vh) scale(0.8799);
    }
    to {
      transform: translate(87.99305vw, 100vh) scale(0.8799);
    }
  }
  .snow:nth-child(185) {
    opacity: 0.3081;
    transform: translate(76.9345vw, -10px) scale(0.7527);
    animation: fall-185 15s -10s linear infinite;
  }
  @keyframes fall-185 {
    52.678% {
      transform: translate(84.1351vw, 52.678vh) scale(0.7527);
    }
    to {
      transform: translate(80.5348vw, 100vh) scale(0.7527);
    }
  }
  .snow:nth-child(186) {
    opacity: 0.7916;
    transform: translate(32.0413vw, -10px) scale(0.5271);
    animation: fall-186 22s -8s linear infinite;
  }
  @keyframes fall-186 {
    31.819% {
      transform: translate(27.9432vw, 31.819vh) scale(0.5271);
    }
    to {
      transform: translate(29.99225vw, 100vh) scale(0.5271);
    }
  }
  .snow:nth-child(187) {
    opacity: 0.0097;
    transform: translate(47.8373vw, -10px) scale(0.5773);
    animation: fall-187 20s -14s linear infinite;
  }
  @keyframes fall-187 {
    69.543% {
      transform: translate(41.8153vw, 69.543vh) scale(0.5773);
    }
    to {
      transform: translate(44.8263vw, 100vh) scale(0.5773);
    }
  }
  .snow:nth-child(188) {
    opacity: 0.0577;
    transform: translate(84.413vw, -10px) scale(0.9006);
    animation: fall-188 26s -20s linear infinite;
  }
  @keyframes fall-188 {
    35.249% {
      transform: translate(93.0152vw, 35.249vh) scale(0.9006);
    }
    to {
      transform: translate(88.7141vw, 100vh) scale(0.9006);
    }
  }
  .snow:nth-child(189) {
    opacity: 0.7577;
    transform: translate(68.4714vw, -10px) scale(0.0039);
    animation: fall-189 24s -30s linear infinite;
  }
  @keyframes fall-189 {
    61.086% {
      transform: translate(77.8952vw, 61.086vh) scale(0.0039);
    }
    to {
      transform: translate(73.1833vw, 100vh) scale(0.0039);
    }
  }
  .snow:nth-child(190) {
    opacity: 0.1881;
    transform: translate(1.1935vw, -10px) scale(0.2136);
    animation: fall-190 21s -6s linear infinite;
  }
  @keyframes fall-190 {
    42.26% {
      transform: translate(-5.049vw, 42.26vh) scale(0.2136);
    }
    to {
      transform: translate(-1.92775vw, 100vh) scale(0.2136);
    }
  }
  .snow:nth-child(191) {
    opacity: 0.7745;
    transform: translate(79.4874vw, -10px) scale(0.2129);
    animation: fall-191 30s -3s linear infinite;
  }
  @keyframes fall-191 {
    63.415% {
      transform: translate(86.4786vw, 63.415vh) scale(0.2129);
    }
    to {
      transform: translate(82.983vw, 100vh) scale(0.2129);
    }
  }
  .snow:nth-child(192) {
    opacity: 0.4223;
    transform: translate(15.9524vw, -10px) scale(0.4628);
    animation: fall-192 11s -10s linear infinite;
  }
  @keyframes fall-192 {
    43.774% {
      transform: translate(15.5197vw, 43.774vh) scale(0.4628);
    }
    to {
      transform: translate(15.73605vw, 100vh) scale(0.4628);
    }
  }
  .snow:nth-child(193) {
    opacity: 0.7795;
    transform: translate(25.3547vw, -10px) scale(0.4667);
    animation: fall-193 17s -17s linear infinite;
  }
  @keyframes fall-193 {
    79.7% {
      transform: translate(27.7364vw, 79.7vh) scale(0.4667);
    }
    to {
      transform: translate(26.54555vw, 100vh) scale(0.4667);
    }
  }
  .snow:nth-child(194) {
    opacity: 0.1926;
    transform: translate(32.7767vw, -10px) scale(0.15);
    animation: fall-194 14s -13s linear infinite;
  }
  @keyframes fall-194 {
    40.41% {
      transform: translate(33.5933vw, 40.41vh) scale(0.15);
    }
    to {
      transform: translate(33.185vw, 100vh) scale(0.15);
    }
  }
  .snow:nth-child(195) {
    opacity: 0.8203;
    transform: translate(38.45vw, -10px) scale(0.6824);
    animation: fall-195 18s -8s linear infinite;
  }
  @keyframes fall-195 {
    31.758% {
      transform: translate(42.9194vw, 31.758vh) scale(0.6824);
    }
    to {
      transform: translate(40.6847vw, 100vh) scale(0.6824);
    }
  }
  .snow:nth-child(196) {
    opacity: 0.1819;
    transform: translate(64.5408vw, -10px) scale(0.9856);
    animation: fall-196 23s -23s linear infinite;
  }
  @keyframes fall-196 {
    37.005% {
      transform: translate(72.267vw, 37.005vh) scale(0.9856);
    }
    to {
      transform: translate(68.4039vw, 100vh) scale(0.9856);
    }
  }
  .snow:nth-child(197) {
    opacity: 0.8239;
    transform: translate(70.4458vw, -10px) scale(0.0711);
    animation: fall-197 12s -22s linear infinite;
  }
  @keyframes fall-197 {
    69.19% {
      transform: translate(61.1354vw, 69.19vh) scale(0.0711);
    }
    to {
      transform: translate(65.7906vw, 100vh) scale(0.0711);
    }
  }
  .snow:nth-child(198) {
    opacity: 0.305;
    transform: translate(78.4159vw, -10px) scale(0.5975);
    animation: fall-198 16s -9s linear infinite;
  }
  @keyframes fall-198 {
    53.799% {
      transform: translate(86.5301vw, 53.799vh) scale(0.5975);
    }
    to {
      transform: translate(82.473vw, 100vh) scale(0.5975);
    }
  }
  .snow:nth-child(199) {
    opacity: 0.767;
    transform: translate(47.405vw, -10px) scale(0.168);
    animation: fall-199 10s -27s linear infinite;
  }
  @keyframes fall-199 {
    52.754% {
      transform: translate(56.6087vw, 52.754vh) scale(0.168);
    }
    to {
      transform: translate(52.00685vw, 100vh) scale(0.168);
    }
  }
  .snow:nth-child(200) {
    opacity: 0.3675;
    transform: translate(19.2187vw, -10px) scale(0.7219);
    animation: fall-200 20s -29s linear infinite;
  }
  @keyframes fall-200 {
    34.767% {
      transform: translate(13.3308vw, 34.767vh) scale(0.7219);
    }
    to {
      transform: translate(16.27475vw, 100vh) scale(0.7219);
    }
  }
`