import React, { ReactNode } from "react";
import styled, { css } from "styled-components";

import Link from 'next/link'

interface Props {
  href?: string;
  className?: string;
  onClick?: () => void;
  type?: "default" | "outline" | "kickstarter" | "outline-invert";
  label: string | ReactNode;
  size?: "small" | "normal";
  target?: "_blank";
  rel?: "noreferrer";
  color?: string;
}

const Button = ({ href, onClick, className="", type = "default", size="normal", label, color, ...props}: Props) => {
  if (href) {
    return (
      <StyledLink href={href} className={`${className} ${type} ${size}`} color={color} {...props}>
        {label}
      </StyledLink>
    )
  }

  return (
    <Action className={`${className} ${type} ${size}`} color={color} onClick={onClick}>
      {label}
    </Action>
  );
};

const sharedStyle = css`
  --primary: black;
  cursor: pointer;
  padding: 0.8rem 1.5rem;
  display: inline-block;
  font-weight: 600;
  font-size: 1rem;
  margin-top: 1.5rem;
  text-decoration: none;
  background: transparent;
  position: relative;
  transition: color 0.3s cubic-bezier(0.77, 0, 0.175, 1), background 0.3s cubic-bezier(0.77, 0, 0.175, 1);

  background-position: 0 100%;
  background-repeat: repeat-x;
  background-size: 4px 0%;

  @media ${({ theme }) => theme.mediaQuery.smallTablet} {
    font-size: 0.9rem;
    padding: 0.8rem 1.8rem;
  }
  
  &:active {
    top: 0.08rem;
  }

  &:hover {
    background-size: 4px 100%;
  }

  // type
  
  &.default {
    color: white;
    background-color: var(--primary);
    border: none;
    
    background-image: linear-gradient(to bottom, white 99%, rgba(0, 0, 0, 0) 100%);

    &:hover {
      background-image: linear-gradient(90deg, white 99%, rgba(0, 0, 0, 0) 100%);
      color: var(--primary);
    }
  }

  &.outline {
    color: var(--primary);
    border: 3px solid var(--primary);
    background-color: transparent;
    background-image: linear-gradient(to bottom, var(--primary) 99%, rgba(0, 0, 0, 0) 100%);

    &:hover {
      background-image: linear-gradient(90deg, var(--primary) 99%, rgba(0, 0, 0, 0) 100%);
      color: white;
    }
  }

  &.outline-invert {
    color: white;
    border: 3px solid white;
    background-color: transparent;
    background-image: linear-gradient(to bottom, white 99%, rgba(0, 0, 0, 0) 100%);

    &:hover {
      background-image: linear-gradient(90deg, white 99%, rgba(0, 0, 0, 0) 100%);
      color: var(--color);
    }
  }

  &.disabled {
    opacity: 0.5;
    pointer-events: none;
  }

  //size

  &.small {
    padding: 0.4rem 0.6rem;
    font-size: 0.9rem;

    @media ${({ theme }) => theme.mediaQuery.smallTablet} {
      font-size: 0.9rem;
      padding: 0.5rem 0.7rem;
    }
  }
`;

const Action = styled.button<Partial<Props>>`
  --color: ${({ color }) => color };
  ${sharedStyle}
`;
const StyledLink = styled(Link)`
  --color: ${({ color }) => color };
  ${sharedStyle}
`;

export default Button;